
import { defineComponent } from 'vue'
import { IonChip, IonAvatar, IonLabel, IonText } from '@ionic/vue'
import IpfsImg from '../components/IpfsImg.vue'
import { UserProfileMixin } from '../mixins/user-profile'

export default defineComponent({
  inject: [
    'ipfs',
  ],
  mixins: [
    UserProfileMixin,
  ],
  components: {
    IonChip,
    IonAvatar,
    IonLabel,
    IonText,
    IpfsImg,
  },
})
