
import { defineComponent, defineAsyncComponent } from 'vue'
import { IonGrid, IonRow, IonCol, IonText, IonToolbar, IonTitle } from '@ionic/vue'
import { getBaseUrl } from '@/utils'

export default defineComponent({
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonToolbar,
    IonTitle,
    ScoreList: defineAsyncComponent(() => import('./scorelist/ScoreList.vue')),
  },
  data () {
    return {
      baseUrl: getBaseUrl(),
      githubUrl: 'https://github.com/LibreScore/LibreScore',
    }
  },
})
