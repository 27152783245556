
import { defineComponent, PropType } from 'vue'
import type { ScoreMetadata } from 'webmscore/schemas'
import { UserPubKeyType } from '@/identity'
import { Source } from '@/core/scorepack'

import { IonToolbar, IonGrid, IonRow, IonCol, IonNote, IonBadge, IonItemDivider } from '@ionic/vue'
import UserChip from '../components/UserChip.vue'
import { PrintTimeMixin, FmtTimeMixin, FmtUrlMixin } from '../mixins/str-fmt'

export default defineComponent({
  inject: [
    'ipfs',
  ],
  mixins: [
    PrintTimeMixin,
    FmtTimeMixin,
    FmtUrlMixin,
  ],
  components: {
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonNote,
    IonBadge,
    IonItemDivider,
    UserChip,
  },
  props: {
    description: {
      type: String,
    },
    /** uploader */
    userPublicKey: {
      type: undefined as any as PropType<UserPubKeyType>,
    },
    /** Tags */
    tags: {
      type: Array as PropType<string[]>,
    },
    /** Sources */
    sources: {
      type: Array as PropType<Source[]>,
    },
    /** Upload Date */
    date: {
      type: Date,
    },
    /** 
     * The score metadata json
     * can retrieve Instruments, Pages, Duration info from it
     */
    metadata: {
      type: Object as PropType<ScoreMetadata>,
    },
  },
  computed: {
    instruments (): string[] {
      if (!this.metadata) return []
      const names = new Set<string>()
      this.metadata.parts.forEach(p => {
        names.add(p.instrumentName)
      })
      return [...names.values()]
    },
    pages (): number | undefined {
      return this.metadata?.pages
    },
    /** score duration in ms */
    duration (): number | undefined {
      if (!this.metadata) return
      return this.metadata.duration * 1000 // convert to ms
    },
  },
})
