
import { defineComponent } from 'vue'
import { IonApp, IonHeader, IonToolbar, IonContent, IonTitle } from '@ionic/vue'

import { ipfsInstance } from '@/ipfs'
import { getBaseUrl } from '@/utils'

export default defineComponent({
  components: {
    IonApp,
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
  },
  provide () {
    return {
      ipfs: ipfsInstance,
    }
  },
  data () {
    return {
      baseUrl: getBaseUrl(),
    }
  },
})
