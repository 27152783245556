
import { defineComponent } from 'vue'

import { IonGrid, IonRow, IonCol } from '@ionic/vue'
import ScoreViewMain from './ScoreViewMain.vue'
import ScoreHeaderBar from './ScoreHeaderBar.vue'
import ScoreInfo from './ScoreInfo.vue'
import ScoreComments from './ScoreComments.vue'

import type { ScoreMetadata } from 'webmscore/schemas'
import ScorePack, { Source } from '@/core/scorepack'
import { fromCid as loadScorePack } from '@/core/scorepack/load'
import { UserPubKeyType } from '@/identity'
import { ipfsFetch } from '@/ipfs'
import { updatePageMetadata } from '@/ui/seo'

export default defineComponent({
  inject: [
    'ipfs',
  ],
  components: {
    IonGrid,
    IonRow,
    IonCol,
    ScoreViewMain,
    ScoreHeaderBar,
    ScoreInfo,
    ScoreComments,
  },
  props: {
    cid: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      _scorepack: undefined as ScorePack | undefined,
      user: undefined as UserPubKeyType | undefined,
      mscz: undefined as Promise<Uint8Array> | undefined,
      metadata: undefined as ScoreMetadata | undefined,

      LOADING: 'Loading...',
    }
  },
  computed: {
    scoreTitle (): string | undefined {
      return this._scorepack?.title
    },
    scoreSummary (): string | undefined {
      return this._scorepack?.summary
    },
    description (): string | undefined {
      return this._scorepack?.description
    },
    tags (): string[] | undefined {
      return this._scorepack?.tags
    },
    sources (): Source[] | undefined {
      return this._scorepack?.source
    },
    date (): Date | undefined {
      if (!this._scorepack) return // no scorepack
      const date = new Date(this._scorepack.updated)
      if (isNaN(date.valueOf())) return // Invalid Date
      return date
    },
  },
  watch: {
    cid: 'init',
    _scorepack (scorepack: ScorePack | undefined) {
      const title = scorepack?.title
      const desc = scorepack?.summary || scorepack?.description
      updatePageMetadata({ title: title?.trim(), description: desc?.trim() || '' })
    },
  },
  methods: {
    async init (): Promise<void> {
      // reset 
      this.mscz = undefined
      this._scorepack = undefined
      this.user = undefined
      this.metadata = undefined

      try {
        this._scorepack = await loadScorePack(this.cid, this['ipfs'])
        if (!this._scorepack) throw new Error('No ScorePack')

        this.mscz = ipfsFetch(this._scorepack.score, this['ipfs'])

        this.user = this._scorepack._sig?.publicKey
      } catch (err) {
        console.error(err)
      }
    },
  },
  created (): Promise<void> {
    return this.init()
  },
})
