
import { defineComponent, PropType } from 'vue'
import { IonToolbar, IonGrid, IonRow, IonCol, IonButton, IonTitle, IonNote } from '@ionic/vue'
import { Action } from '../components/ActionList.vue'

export type ActionGroups = Action[]

export default defineComponent({
  components: {
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonTitle,
    IonNote,
  },
  props: {
    actions: {
      type: Array as PropType<ActionGroups>,
    },
    /**
     * score `title` from the *ScorePack*
     */
    scoreTitle: {
      type: String,
      required: true,
      default: ' ', // em space
    },
    /**
     * score `summary` from the *ScorePack*
     */
    scoreSummary: {
      type: String,
    },
  },
  data () {
    return {
    }
  },
  methods: {
    /**
     * Disable the button when processing
     */
    async onClick (e: MouseEvent, action: Action): Promise<void> {
      const el = e.target as HTMLIonButtonElement
      el.disabled = true
      await action.fn()
      el.disabled = false
    },
  },
})
