
import { defineComponent } from 'vue'

import { IonCardTitle, IonTextarea, IonButton } from '@ionic/vue'

export default defineComponent({
  components: {
    IonCardTitle,
    IonTextarea,
    IonButton,
  },
})
